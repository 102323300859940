import React from 'react';

import Logo from '../images/logo.svg';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import tw from 'tailwind.macro';
import { LinkButtonPrimary, LinkButtonSecondary } from '../components/ui';

const P = styled.p`
    ${tw`mb-6 font-sm leading-relaxed relative z-10`}
`;

function NotFoundPage() {
    return (
        <Layout siteTitle="404">
            <SEO title="404: Seite nicht gefunden" />
            <div className="bg-gradient-top pb-24 md:pb-34">
                <div className="container mx-auto pt-12 mb-24 md:mb-32 flex items-center justify-center md:justify-start z-10">
                    <Link to={`/`}>
                        <img src={Logo} alt="Logo" className="object-contain w-full h-full mb-0" />
                    </Link>
                </div>
                <div className="container mx-auto flex flex-col relative">
                    <h1 className="mb-4 text-3xl md:text-4xl font-bold leading-tight relative z-10">
                        404 - Seite nicht gefunden
                    </h1>
                    <P>Leider ist die gewünschte Seite nicht verfügbar.</P>
                    <P>
                        <LinkButtonPrimary text="Zur Startseite" link="/" />
                        <LinkButtonSecondary
                            text="Kontakt aufnehmen"
                            link="mailto:info@swisspeers.ch"
                        ></LinkButtonSecondary>
                    </P>
                </div>
            </div>
        </Layout>
    );
}

export default NotFoundPage;
