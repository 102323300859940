import React from "react"
import styled from "@emotion/styled"
import { motion } from "framer-motion"
import tw from "tailwind.macro"

type ButtonProps = {
  text: string
  link?: string
}
export const IconLink = styled.a`
  ${tw`hover:text-green-500 flex items-center`}
`

export const LinkButtonPrimary = ({ text, link = "#" }: ButtonProps) => (
  <motion.a
    className="inline-flex bg-yellow-400 text-cyan-700 hover:bg-yellow-500 hover:text-white hover:shadow-xl text-sm font-semibold py-2 px-4 rounded-full py-3 px-10 focus:outline-none focus:shadow-outline shadow-lg mr-8 mb-4"
    href={link}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.9 }}
  >
    {text}
  </motion.a>
)

export const LinkButtonSecondary = ({ text, link = "#" }: ButtonProps) => (
  <motion.a
    className="inline-flex bg-grey-200 hover:bg-yellow-500 hover:text-white hover:shadow-xl text-cyan-700 text-sm font-semibold py-2 px-4 rounded-full py-3 px-10 focus:outline-none focus:shadow-outline shadow-lg mr-8 mb-4"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.9 }}
    href={link}
  >
    {text}
  </motion.a>
)
